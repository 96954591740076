import React from "react";
import { LayoutGeneral } from "src/layout/LayoutGeneral";
import { RollbackOutlined } from "@ant-design/icons";
import { Link } from "gatsby";
import { Layout } from "antd";
import Florida1 from "src/components/referencias/images/exterior/casa-bertin-osborne-tele5/climateCoating-thermoProtect-la-florida1.jpg";
import Florida2 from "src/components/referencias/images/exterior/casa-bertin-osborne-tele5/climateCoating-thermoProtect-la-florida2.jpg";
import Florida3 from "src/components/referencias/images/exterior/casa-bertin-osborne-tele5/climateCoating-thermoProtect-la-florida3.jpg";
import Florida4 from "src/components/referencias/images/exterior/casa-bertin-osborne-tele5/climateCoating-thermoProtect-la-florida4.jpg";
import Florida5 from "src/components/referencias/images/exterior/casa-bertin-osborne-tele5/climateCoating-thermoProtect-la-florida5.jpg";
import Florida6 from "src/components/referencias/images/exterior/casa-bertin-osborne-tele5/climateCoating-thermoProtect-la-florida6.jpg";

import { ThermoProtectImgAndLinks } from "src/components/referencias/exterior/ThermoProtect";

import { FacebookOutlined, InstagramOutlined } from "@ant-design/icons";

import { Image } from "antd";
import { List, Card } from "antd";
const { Header, Content } = Layout;

const data = [
  {
    src: Florida1,
  },
  {
    src: Florida2,
  },
  {
    src: Florida3,
  },
  {
    src: Florida4,
  },
  {
    src: Florida5,
  },
  {
    src: Florida6,
  },
];

const CasaDeBertinOsborne = (props) => (
  <LayoutGeneral
    {...{
      ...props,
      showPageTitle: false,
      pageTitle: "ThermoProtect: Casa de Bertín Osborne en Tele5",
      pageName: "Referencia ThermoProtect",
      pageDescription:
        "ClimateCoating ThermoProtect: Usado en el la casa del programa En tu casa o en la mía de Bertín Osborne",
    }}
  >
    <Content className="thermoProtect referencia">
      <div className="linkBack referencia">
        <Link to="/referencias/exterior/">
          <RollbackOutlined />{" "}
          <span className="colored linkText">Ir a Referencias Exterior</span>
        </Link>
      </div>

      <div className="marginTop50">
        <ThermoProtectImgAndLinks />
        <h1 className="pageTitle referencia">
          <b className="thermoProtect">ClimateCoating ThermoProtect: </b>
          <span className="secondText">
            Unifamiliar utilizado en el programa de Tele5 "En tu casa o en la
            Mía"
          </span>
        </h1>
        <p>
          Cómo puede ver en detalle en la página de
          <Link to={"/productos/thermoprotect/"} className="grey bold">
            ThermoProtect
          </Link>
          , sus ventajas no sólo se limitan al ahorro energético conseguido
          gracias a su uso.
        </p>
        <p>
          En un proyecto de la dimensión y medios de esta casa unifamiliar, la
          estética y acabado exterior son críticas. Al haber escogido el color
          blanco,{" "}
          <strong className="grey">
            cualquier humedad o filtración se aprecia de forma inmediata y puede
            deslucir el aspecto del conjunto
          </strong>
          .
        </p>
        <p>
          La edificación se encuentra pegada al Monte del Pardo y está rodeada
          de naturaleza, no dispone por lo tanto de ninguna barrera que
          disminuya su exposición a los agentes erosivos. Era necesario por lo
          tanto recurrir a una
          <strong className="grey">
            {" "}
            solución que permitiese lograr un acabado de la máxima calidad de
            manera duradera
          </strong>
          , sin que ello implicase un mayor gasto en mantemiento.
        </p>

        <p>
          Como puede verse en la multitud de planos del programa de televisión,
          la casa se encuentra en perfecto estado de revista.
        </p>

        <p>
          Como punto adicional, en estas casas unifamiliares los habitantes
          pasan cierto tiempo en las inmediaciones de las paredes donde se ha
          aplicado el producto, bien en partes del jardín pegadas a la pared o
          en la misma piscina. El disponer de una{" "}
          <strong className="grey">
            solución ecológica y amigable con el medio ambiente
          </strong>{" "}
          da mucha tranquilidad a los ocupantes, sobre todo en el caso de que
          hay niños o personas con problemas respiratorios.
        </p>
        <br />
        <br />
      </div>
      <div className="clearer"></div>
      <Image.PreviewGroup preview={{}}>
        <List
          grid={{ gutter: 16, column: 3 }}
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <Image src={item.src} />
            </List.Item>
          )}
        />
      </Image.PreviewGroup>
      <br />
      <br />
    </Content>
  </LayoutGeneral>
);

export default CasaDeBertinOsborne;
